import React from 'react'
import { graphql } from 'gatsby'
import { FeaturedProducts, Banner, Layout, Seo, Advantages, Testimonials, Collections, FaqHome } from '../components'

const IndexPage = ({ data: { products } }) => {
  return (
    <Layout>
      <Seo title="Takween" />
      <Banner />
      <Collections />
      <FeaturedProducts products={products.nodes} />
      <Testimonials />
      <Advantages />

      <FaqHome />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  {
    products: allShopifyProduct(sort: { fields: publishedAt, order: DESC }, limit: 4) {
      nodes {
        ...ProductCard
      }
    }
  }
`
